// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import jquery from "jquery"
window.$ = jquery
window.jQuery = jquery

import "bootstrap/dist/js/bootstrap"

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import { initializeApp } from "firebase/app";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, sendSignInLinkToEmail, GoogleAuthProvider, OAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult, getIdToken } from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAEyJkdorI6GFxblWNcyxSmgTsASDUPJSA",
    authDomain: "orb-app-b44f4.firebaseapp.com",
    projectId: "orb-app-b44f4",
    storageBucket: "orb-app-b44f4.appspot.com",
    messagingSenderId: "236972997264",
    appId: "1:236972997264:web:9983c453b26aaf5b01b615",
    measurementId: "G-XB31MEHH1E"
  };

const app = initializeApp(firebaseConfig);
window.auth = getAuth(app);

window.googleprovider = new GoogleAuthProvider();
window.appleprovider = new OAuthProvider('apple.com');

window.GoogleAuthProvider = GoogleAuthProvider;
window.OAuthProvider = OAuthProvider;
window.getIdToken = getIdToken;
window.isSignInWithEmailLink = isSignInWithEmailLink;
window.signInWithEmailLink = signInWithEmailLink;
window.sendSignInLinkToEmail = sendSignInLinkToEmail;
window.signInWithPopup = signInWithPopup;
window.signInWithRedirect = signInWithRedirect;
window.getRedirectResult = getRedirectResult;
